import React, { useState, useEffect, useContext } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import '../assets/css/chatComponent.css';
import '../assets/css/leftColumn.css';
import { WalletContext } from '../context/WalletContext';
const Cookies = require('js-cookie');

const mockNFTData = {
    "success": true,
    "message": "All NFTs in your wallet",
    "result": [
        {
            "name": "General #15",
            "symbol": "ZoneX",
            "royalty": 7,
            "image_uri": "https://nftstorage.link/ipfs/bafkreiaond3gnbm4d7xfx3fwp3lqki4cymtog4hiyyvef2d7mxwmh3tyji",
            "description": "Part of ZoneX New Era collection",
            "mint": "2Ag1JxDcxYoJzEnBScpAQNqVBFGmvvdfa4F6TWEyn8UH",
            "owner": "7PTQYAesyeF8Fi5Ku5zfiFsTHUy3ATgV8EzuLJR2inTK",
            "update_authority": "7PTQYAesyeF8Fi5Ku5zfiFsTHUy3ATgV8EzuLJR2inTK",
            "creators": [
                {
                    "address": "BgKGxBQ244snfnDzwtUxwtrNZu8XMpRyC1MmukomZWhD",
                    "share": 0,
                    "verified": true
                },
                {
                    "address": "7PTQYAesyeF8Fi5Ku5zfiFsTHUy3ATgV8EzuLJR2inTK",
                    "share": 0,
                    "verified": false
                },
                {
                    "address": "99ifGorVzsszjVbViYMsnsSM3mpak6ZCXKY2sUyyFtYK",
                    "share": 100,
                    "verified": false
                }
            ],
            "collection": {
                "address": "5rCTsymtJy1b29mgUufXHEMf46s68ZhG7hPM9hptT2Pj",
                "verified": true
            },
            "attributes": [
                { "trait_type": "Skin", "value": "5" },
                { "trait_type": "Tattoo", "value": "15" },
                { "trait_type": "Eyes", "value": "Daily 1" },
                { "trait_type": "Uniforms", "value": "RU Ceremonial General" },
                { "trait_type": "Mouth", "value": "Angry" },
                { "trait_type": "Attributes", "value": "Stolen painting" }
            ],
            "status": ""
        },
        {
            "name": "Soldier #1115",
            "symbol": "ZoneX",
            "royalty": 7,
            "image_uri": "https://gateway.pinata.cloud/ipfs/QmRAZeY7bkn1J9zRkyrKGVSH7ksjBDR9U8rCNANSdHmL1Y/196.png",
            "description": "Part of ZoneX New Era collection",
            "mint": "Dvmhh7UsXFdZW6UXdZX3W1VbTDF41sLXJGxByebhQsLF",
            "owner": "FyDYfVsK8qkUT6cfHd4Pi51yK1xgV1w1nd8X1VPTorz8",
            "update_authority": "2SUqj8k5j6GEk3xDzgKXCqjBhpxotMmUhJeMDZTCSQZN",
            "creators": [
                {
                    "address": "2SUqj8k5j6GEk3xDzgKXCqjBhpxotMmUhJeMDZTCSQZN",
                    "share": 0,
                    "verified": false
                },
                {
                    "address": "YerHDACrULgh6KavVycEPF8BBzpMy7VeaWKEC6dDypc",
                    "share": 100,
                    "verified": false
                }
            ],
            "collection": {
                "address": "5rCTsymtJy1b29mgUufXHEMf46s68ZhG7hPM9hptT2Pj",
                "verified": true
            },
            "attributes": [
                { "trait_type": "Skin", "value": "Wave I" },
                { "trait_type": "Tattoo", "value": "1115" },
                { "trait_type": "Uniforms", "value": "CN Uniform XV" },
                { "trait_type": "Eyes", "value": "Sus III" },
                { "trait_type": "Mouth", "value": "Unsatisfied" },
                { "trait_type": "Attributes", "value": "Tea and Biscuits" }
            ],
            "status": ""
        }
    ]
};

const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount);
};

const NFTTable = ({ nfts, walletAddress }) => {
    const airdropAmounts = {
        Soldier: 924.40,
        Sergeant: 4314.20,
        Officer: 11005.80,
        Lieutenant: 28890.60,
        Colonel: 192604.00,
        General: 616332.80
    };

    const getAirdropAmount = (name) => {
        const rank = Object.keys(airdropAmounts).find(rank => name.includes(rank));
        return airdropAmounts[rank] || 0;
    };

    const totalAirdrop = nfts.reduce((sum, nft) => sum + (nft.status !== "done" ? getAirdropAmount(nft.name) : 0), 0);

    const soldierCount = nfts.filter(nft => nft.name.includes('Soldier') && nft.status !== "done").length;
    const hasBonus = soldierCount >= 5 && nfts.some(nft => !nft.name.includes('Soldier') && nft.status !== "done");
    const bonusAmount = hasBonus ? nfts.reduce((sum, nft) => {
        if (!nft.name.includes('Soldier') && nft.status !== "done") {
            return sum + getAirdropAmount(nft.name) * 0.01;
        }
        return sum;
    }, 0) : 0;

    const [isAirdropButtonDisabled, setAirdropButtonDisabled] = useState(false);
    const [airdropButtonText, setAirdropButtonText] = useState(`Get AIRDROP ${formatCurrency(Math.round(totalAirdrop + bonusAmount))} ZEXY`);

    const handleGetAirdrop = async () => {
        setAirdropButtonDisabled(true);
        setAirdropButtonText('AIRDROP will be reflected within an hour');

        const eligibleNFTs = nfts
        .filter(nft => nft.status !== "done")
        .map(nft => {
            const numberMatch = nft.name.match(/\d+/);
            return numberMatch ? parseInt(numberMatch[0], 10) : null;
        })
        .filter(nftNumber => nftNumber !== null);
        
        const headers = new Headers();
        headers.append("Authorization", "Bearer " + Cookies.get('nft_utoken'));
        headers.append("Content-Type", "application/json");

        const raw = JSON.stringify({ wallet: walletAddress, nfts: eligibleNFTs });

        const requestOptions = {
            method: "POST",
            credentials: 'include',
            headers: headers,
            body: raw,
            redirect: "follow"
        }; 

        try {
            const response = await fetch("https://api.zonexlegacy.com/nft/getAirDrop", requestOptions);
            const result = await response.json();
            console.log('Airdrop result:', result);
        } catch (error) {
            console.error('Error getting airdrop:', error);
        }
    };

    return (
        <div>
            <table className="table table-striped table-custom-width">
                <thead>
                    <tr>
                        <th style={{ width: '50px' }}>Image</th>
                        <th style={{ width: '250px' }}>Title</th>
                        <th style={{ width: '150px' }}>Airdrop Amount (ZEXY)</th>
                    </tr>
                </thead>
                <tbody>
                    {nfts.map((nft, index) => (
                        <tr key={index}>
                            <td style={{ width: '50px' }}><img src={nft.image_uri} alt={nft.name} style={{ width: '50px' }} /></td>
                            <td>{nft.name}</td>
                            <td>
                                {nft.status === "done" ? "Airdrop already received" : `${formatCurrency(Math.round(getAirdropAmount(nft.name)))} ZEXY`}
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan="2">NFT AirDrop</td>
                        <td>{formatCurrency(Math.round(totalAirdrop))} ZEXY</td>
                    </tr>
                    {hasBonus && (
                        <tr>
                            <td colSpan="2">Bonus army</td>
                            <td>{formatCurrency(Math.round(bonusAmount))} ZEXY</td>
                        </tr>
                    )}
                    <tr>
                        <td colSpan="2">Total AirDrop</td>
                        <td>{formatCurrency(Math.round(totalAirdrop + bonusAmount))} ZEXY</td>
                    </tr>
                </tbody>
            </table>
            <button
                className="get-airdrop-button"
                onClick={handleGetAirdrop}
                disabled={isAirdropButtonDisabled}
            >
                {airdropButtonText}
            </button>
        </div>
    );
};

const AirDropPage = () => {
    const [myNFTs, setMyNFTs] = useState([]);
    const { isLoggedIn, walletAddress, login } = useContext(WalletContext);
    /*
    const [isLoggedIn, setIsLoggedIn] = useState(true);   
    const [walletAddress, setWalletAddress] = useState("5gC3XRAPDSJm1TmP77QNT1vEXprSQVxDr2is8FwnEhcZ");
    const login = () => {
        setIsLoggedIn(true);
        setWalletAddress("5gC3XRAPDSJm1TmP77QNT1vEXprSQVxDr2is8FwnEhcZ");
    };
    */
    useEffect(() => {
        if (walletAddress) {
            async function fetchData() {
                const result = await fetchMyNFTs(walletAddress);
                setMyNFTs(result);
            }
            fetchData();
        }
    }, [walletAddress]);

    const userVerified = localStorage.getItem('userVerified') === 'true';

    return (
        <div className="terminal-page-container">
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">AirDrop Page</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-contact tf-section terminal-container">
                <div className="themesflat-container">
                    <div className="row align-items-start">
                        {isLoggedIn && walletAddress && userVerified ? (
                            myNFTs.length > 0 ? (
                                <NFTTable nfts={myNFTs} walletAddress={walletAddress}/>
                            ) : (
                                <div className="col-md-12 text-center">
                                    <p>There are no NFTs to display</p>
                                </div>
                            )
                        ) : (
                            <div className="col-md-12 text-center">
                                <p>You are not logged in. Please log in to view your NFTs.</p>
                                <button onClick={login} className="sc-button header-slider style style-1 wallet fl-button pri-1">
                                    <span>Wallet connect</span>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

async function fetchMyNFTs(walletAddress) {
    try {
        //return mockNFTData.result;
        const headers = new Headers();
        headers.append("Authorization", "Bearer " + Cookies.get('nft_utoken'));
        headers.append("Content-Type", "application/json");

        const raw = JSON.stringify({ "publicKey": walletAddress });

        const requestOptions = {
            method: "POST",
            credentials: 'include',
            headers: headers,
            body: raw,
            redirect: "follow"
        };

        const response = await fetch("https://api.zonexlegacy.com/nft/nftReadAllWallet", requestOptions);
        const result = await response.json();
        if (result.success && result.result.length > 0) {
            return result.result;
        }
    } catch (error) {
        console.error(error);
    }
    return [];
}

export default AirDropPage;
